import React, { useState } from 'react';

export default function QuickEnquiry() {
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [mobile, setMobile] = useState('');
const [message, setMessage] = useState('');

const handleSubmit = async (e) => {
  e.preventDefault();

  // Send the form data as JSON
  const response = await fetch('https://demo.educatorians.com/send_email.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      email,
      mobile,
      message,
    }),
  });

  const result = await response.json();
  if (result.status === 'success') {
    alert('Message sent successfully!');
  } else {
    alert(`Error: ${result.message}`);
  }
};
  return (
    
    <section className="slogen-section">
    <div className="container">
      <h2 style={{ color: '#fff', marginBottom: '20px' }}>QUICK ENQUIRY</h2>
      
      <form onSubmit={handleSubmit}>
      <div className="col-md-6">
        <div className="form-group">
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            name="mobile"
            className="form-control"
            placeholder="Enter Mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
          />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <textarea
            name="message"
            placeholder="Message..."
            required
            style={{ height: '132px' }}
            className="form-control"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>

        <button
          type="submit"
          name="submit"
          style={{
            background: '#E72E77',
            border: '1px solid #E72E77',
            paddingLeft: '40px',
            paddingRight: '40px',
            paddingTop: '8px',
            paddingBottom: '8px',
            color: '#fff',
            borderRadius: '100px',
          }}
        >
          Submit
        </button>
      </div>
    </form>
    </div>
  </section>
  )
}
