import React, { useState } from "react";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send the form data as JSON
    const response = await fetch(
      "https://demo.educatorians.com/send_email.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          mobile,
          message,
        }),
      }
    );

    const result = await response.json();
    if (result.status === "success") {
      alert("Message sent successfully!");
    } else {
      alert(`Error: ${result.message}`);
    }
  };
  return (
    <div className="page">
      <section className="contackForm">
        <div className="container">
          <div className="row">
            <div className="heading">
              <div className="icon">
                <em className="icon icon-heading-icon"></em>
              </div>
              <div className="text">
                <h2>Contact Us</h2>
              </div>
              <div className="stickLine"></div>
            </div>
            <div className="col-sm-12">
              <h2>Contact Form</h2>
            </div>
            <form method="POST" onSubmit={handleSubmit}>
              <div className="col-sm-6">
                <div className="input-box">
                  <label>
                    Your Name <sup>*</sup>
                  </label>
                  <input
                    placeholder="Enter Name"
                    type="text"
                    name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-box">
                  <label>Your Email</label>
                  <input
                    placeholder="Enter Email"
                    type="text"
                    name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-box">
                  <label>
                    Mobile No <sup>*</sup>
                  </label>
                  <input
                    placeholder="Enter Mobile"
                    type="text"
                    name="mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
                    maxLength="10"
                    pattern="^[6-9]\d{9}$"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-box">
                  <label>Your Message</label>
                  <textarea
                    placeholder="Enter Message"
                    name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <input
                  type="submit"
                  name="submit"
                  className="btn"
                  value="Submit"
                />
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container">
          <div className="home-event">
            <div className="row">
              <div className="col-sm-4">
                <div className="contact-box">
                  <div className="contactIcon">
                    <span className="icon icon-phone"></span>
                  </div>
                  <a href="#">+91 1111111111</a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact-box">
                  <div className="contactIcon">
                    <span className="icon icon-location-1"></span>
                  </div>
                  <address>
                  Address Address Address Adress Adress PINCODE <br />
                  PINCODE - PINCODE
                  </address>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="contact-box">
                  <div className="contactIcon">
                    <span className="icon icon-message"></span>
                  </div>
                  <span>
                    Email -{" "}
                    <a href="mailto:email@gmail.com">
                    email@gmail.com
                    </a>
                  </span>
                  <span>
                    Website - <a href="#">ReactEvent.com</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
