import React from 'react';
import QuickEnquiry from '../components/QuickEnquiry';

const BrandPromotion = () => {
  return (
    <div style={{ backgroundColor: 'rgb(225, 248, 255)' }}>
      <div className="page">
        <img style={{width:'100%'}}  src="image/slider/Banner10.jpg" alt="" />
        {/* End Banner */}

        <section className="content">
          <div className="container">
            <div className="blog-page">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="blog-slide">
                  <div className="blog-info">
                    <div className="sub-title3">
                      <h2>Brand Promotion</h2>
                    </div>
                    <p style={{ textAlign: 'justify' }}>
                      Celebrate in style with DBS! We understand that brand
                      promotions are more than just business events; they are
                      opportunities for joy, laughter, and celebration. Whether
                      you're organizing a milestone brand promotion or a special
                      gathering to honor a loved one within the context of your
                      brand, our team of skilled event planners is dedicated to
                      crafting an unforgettable brand promotion experience.
                    </p>
                    <p>
                      <strong>Event Planning and Design: </strong>At DBS,
                      we believe that your brand promotion should reflect your
                      unique personality and the values of your company. From
                      innovative product launches to engaging corporate parties,
                      we meticulously plan every detail to ensure your event
                      leaves a lasting impression.
                    </p>
                    <p>
                      With our expertise in event planning, we create
                      atmospheres filled with excitement and professionalism,
                      where every element—from selecting the perfect venue to
                      coordinating branding, decor, catering, and
                      entertainment—is carefully curated to exceed your
                      expectations.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="right-blog">
                  <div className="subscribe-blog">
                    {/* Unused form code */}
                    <img src="image/service/4.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="otherInfo">
          <div className="container">
            <div className="heading">
              <div className="icon">
                <em className="icon icon-heading-icon"></em>
              </div>
              <div className="text">
                <h2>Brand Promotion Services</h2>
              </div>
              <div className="stickLine"></div>

              <div className="service-first">
                <div className="serv2">
                  <img src="image/service/pandal.jpg" className="serv-icons" alt="" />
                  <h3>Fancy Pandal</h3>
                  <p style={{ marginLeft: 10, marginRight: 10 }}>
                    DBS's fancy pandal in your city features exquisite design and luxurious amenities, offering an unforgettable experience for all attendees.
                  </p>
                </div>

                <div className="serv2">
                  <img src="image/service/flower.jpg" className="serv-icons" alt="" />
                  <h3>Flower Decoration</h3>
                  <p style={{ marginLeft: 10, marginRight: 10 }}>
                    DBS's flower decorations in your city are renowned for their artistic elegance and meticulous attention to detail, creating enchanting settings for any occasion.
                  </p>
                </div>

                <div className="serv2">
                  <img src="image/service/musicband.jpg" className="serv-icons" alt="" />
                  <h3>Music Band</h3>
                  <p style={{ marginLeft: 10, marginRight: 10 }}>
                    DBS's live music band in your city delivers captivating performances with soulful singing and energetic melodies, ensuring a memorable atmosphere for all guests.
                  </p>
                </div>

                <div className="break"></div>

                <div className="serv2">
                  <img src="image/service/catering.jpg" className="serv-icons" alt="" />
                  <h3>Catering Service</h3>
                  <p style={{ marginLeft: 10, marginRight: 10 }}>
                    DBS's catering service in your city offers a delightful array of culinary delights with impeccable presentation and exceptional taste, perfect for elevating any event to a memorable occasion.
                  </p>
                </div>

                <div className="serv2">
                  <img src="image/service/photography.jpg" className="serv-icons" alt="" />
                  <h3>Photography & Videography</h3>
                  <p style={{ marginLeft: 10, marginRight: 10 }}>
                    DBS's photography and videography services in your city capture the essence of every moment with artistic flair and professional expertise, ensuring lasting memories of your special occasions.
                  </p>
                </div>

                <div className="serv2">
                  <img src="image/service/drone.jpg" className="serv-icons" alt="" />
                  <h3>Drone Camera & CCTV</h3>
                  <p style={{ marginLeft: 10, marginRight: 10 }}>
                    DBS's drone camera and CCTV services in your city provide cutting-edge aerial and surveillance capabilities, enhancing event security and capturing stunning perspectives from above.
                  </p>
                </div>

                <div className="break"></div>
              </div>
            </div>
          </div>
        </section>
        <QuickEnquiry/>
      </div>
    </div>
  );
};

export default BrandPromotion;
