import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
// import Carousel from 'react-bootstrap/Carousel';
import Gallery from "../components/Gallery";
import QuickEnquiry from "../components/QuickEnquiry";
import Slider from "../components/slider/Slider";
// import 'bootstrap/dist/css/bootstrap.min.css';

const HomePage = () => {

  const imageUrls = [
    "image/slider/slider.jpg",
    "image/slider/slider1.jpg",
    "image/slider/slider2.jpg",
  ];

  return (
    <>
    
    <div style={{backgroundColor: 'rgb(225 248 255)'}}>
    <div className="page">
      <Slider images={imageUrls} interval={5000} />

      {/* Our History Section */}
      <section className="our-history style3">
        <div className="container under_column_992">
          <div className="pull-right col-md-6" style={{ textAlign: 'center' }}>
            <div className="about_pic">
              <img className="rounded_2" style={{position:'relative'}} src="admin/gallery/1.jpg" alt="" />
            </div>
          </div>
          <div className="pull-right col-md-6">
            <div className="sub-title2 left-text">
              <h2>Welcome to <span style={{ color: '#DE2C72' }}>DBS</span></h2>
            </div>
            <p style={{ textAlign: 'justify' }}>
              DBS, located in Samastipur, Bihar, is renowned for its exceptional wedding planning and event management services. 
              With a strong commitment to helping families achieve their dream weddings, DBS has established itself as a trusted 
              name in the industry.
            </p>
            <div className="col-md-6">
              <p style={{ color: '#000', fontWeight: 'bold' }}><i className="fa fa-check-square-o"></i> Fancy Pandal</p>
              <p style={{ color: '#000', fontWeight: 'bold' }}><i className="fa fa-check-square-o"></i> Light Decoration</p>
              <p style={{ color: '#000', fontWeight: 'bold' }}><i className="fa fa-check-square-o"></i> Music Band (Live Singing)</p>
              <p style={{ color: '#000', fontWeight: 'bold' }}><i className="fa fa-check-square-o"></i> DJ Sound</p>
              <p style={{ color: '#000', fontWeight: 'bold' }}><i className="fa fa-check-square-o"></i> Clod Fire, Dry Ice</p>
            </div>
            <div className="col-md-6">
              <p style={{ color: '#000', fontWeight: 'bold' }}><i className="fa fa-check-square-o"></i> Flower Decoration</p>
              <p style={{ color: '#000', fontWeight: 'bold' }}><i className="fa fa-check-square-o"></i> Photography & Videography</p>
              <p style={{ color: '#000', fontWeight: 'bold' }}><i className="fa fa-check-square-o"></i> Drone Camera, TV</p>
              <p style={{ color: '#000', fontWeight: 'bold' }}><i className="fa fa-check-square-o"></i> Catering</p>
              <p style={{ color: '#000', fontWeight: 'bold' }}><i className="fa fa-check-square-o"></i> Beautician at Door Step (Bride & Groom)</p>
            </div>
            <Link to="/about-us" className="btn style2">Read More</Link>
          </div>
        </div>
      </section>

      {/* Service First Section */}
      <div className="service-first">
        <h2>Our Services</h2>
        <Link to="/contact-us">
          <div className="serv1">
            <img src="image/band-pormotion.jpg" className="serv-icon" alt="" />
            <h3>Music Band</h3>
          </div>
        </Link>
        <Link to="/contact-us">
          <div className="serv1">
            <img src="image/theme-based-party.jpg" className="serv-icon" alt="" />
            <h3>Flower Decoration</h3>
          </div>
        </Link>
        <Link to="/contact-us">
          <div className="serv1">
            <img src="image/Catering-Service.jpg" className="serv-icon" alt="" />
            <h3>Catering Services</h3>
          </div>
        </Link>
        <Link to="/contact-us">
          <div className="serv1">
            <img src="image/ring-ceremony.jpg" className="serv-icon" alt="" />
            <h3>Photo & Videography</h3>
          </div>
        </Link>
      </div>

      {/* Why We Choose DBS Section */}
      <section className="content">
        <div className="container">
          <div className="home-event home_event2">
            <div className="heading">
              <div className="icon"><em className="icon icon-heading-icon"></em></div>
              <div className="text">
                <h2>Why We Choose DBS</h2>
              </div>
              <div className="stickLine"></div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="text-center">
                  <p style={{ textAlign: 'justify' }}>
                  Choosing DBS guarantees an exceptional standard of service, marked by impeccable attention to detail, boundless creativity, and unwavering professionalism in every aspect of event organization. Our team is deeply committed to ensuring client satisfaction, going above and beyond to craft bespoke experiences that resonate with personal tastes and preferences. Whether it's a wedding, birthday celebration, corporate event, or any special occasion, DBS stands out for its ability to transform visions into reality, earning a reputation as a trusted and reliable partner in making moments unforgettable.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Gallery Section */}
      <Gallery/>
      <QuickEnquiry/>
      </div>
    </div>
    </>
  );
};

export default HomePage;
