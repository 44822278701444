import React from 'react';
// import './AboutLS.css'; // Import your CSS file if needed

const AboutLS = () => {
    return (
        <div style={{ backgroundColor: 'rgb(225, 248, 255)' }}>
            <div className="page">
                <section className="content">
                    <div className="container">
                        <div className="home-event">
                            <div className="heading">
                                <div className="icon">
                                    <em className="icon icon-heading-icon"></em>
                                </div>
                                <div className="text">
                                    <h2>About DBS</h2>
                                </div>
                                <div className="stickLine"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="text-center">
                                        <p style={{ textAlign: 'justify', fontSize: '15px' }}>
                                            <i>
                                                At DBS, we prioritize our clients' objectives, ensuring a dedicated and secure event environment. From venue and theme to décor, lighting, and music, we adhere to rigorous safety, cultural, and ethical standards. Our comprehensive services ensure an exceptional event experience, efficiently managing setup, rehearsal, and teardown. With our dynamic event planners, we turn every occasion into a resounding success. Contact us today to see how we create unforgettable events with expertise and flair!
                                            </i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="aboutUs">
                    <div className="ourText">
                        <h2>
                            <span style={{ color: '#1C1F23' }}>BEST EVENT</span> <span>MANAGEMENT IN SAMASTIPUR</span>
                        </h2>
                        <p style={{ textAlign: 'justify', color: '#000' }}>
                            DBS is celebrated for its exceptional wedding planning and event management services across Samastipur, Muzaffarpur, Vaishali, Hajipur, and Begusarai. Committed to realizing dream weddings, DBS has earned a trusted reputation in the industry. Their experienced team ensures meticulous planning and flawless execution for a variety of events, including weddings, corporate gatherings, social functions, and conferences, consistently delivering excellence in every celebration.
                        </p>
                    </div>
                    <div className="ourImg">
                        <img src="image/slider/slider1.jpg" alt="about us" />
                    </div>
                </section>
                <section className="visionGoals">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 style={{ textAlign: 'center', color: '#000' }}>Our Vision</h1>
                                <p style={{ textAlign: 'justify' }}>
                                    Our aim is to lead as the premier Event Company in Samastipur, Bihar, providing a comprehensive range of outstanding events, décor, and related services seamlessly. We prioritize affordability alongside uncompromising quality to ensure complete client satisfaction in every service we offer.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 style={{ textAlign: 'center', color: '#000' }}>Mission</h1>
                                <p style={{ textAlign: 'justify' }}>
                                    As a professional event planning firm, our primary focus is on crafting and delivering unforgettable moments that resonate with life's most cherished memories. We pride ourselves on our ability to provide exceptional decorations and cost-effective solutions, ensuring every event is marked by impeccable precision, style, and grace. Whether it's a wedding, corporate gathering, social function, or any special occasion, our dedicated team combines creativity with meticulous planning to exceed client expectations. We strive to create atmospheres that are not only visually stunning but also perfectly aligned with our clients' visions and preferences. With a commitment to excellence and attention to detail, we aim to transform ideas into reality, making each event a seamless and memorable experience. Our approach is rooted in professionalism, innovation, and a genuine passion for celebrating life's milestones in the most meaningful and spectacular ways possible.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="otherInfo">
                    <div className="container">
                        <div className="heading">
                            <div className="icon">
                                <em className="icon icon-heading-icon"></em>
                            </div>
                            <div className="text">
                                <h2>Our Service</h2>
                            </div>
                            <div className="stickLine"></div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-3">
                                    <ul className="customList">
                                        <li>Wedding Planner</li>
                                        <li>CCTV</li>
                                        <li>Music Band</li>
                                        <li>Theme Decoration</li>
                                        <li>Birthday Party</li>
                                    </ul>
                                </div>
                                <div className="col-sm-3">
                                    <ul className="customList">
                                        <li>Corporate Event</li>
                                        <li>Photo & Videography</li>
                                        <li>Theme Decoration</li>
                                        <li>Birthday Party</li>
                                        <li>Cold Fire & Dry Ice</li>
                                    </ul>
                                </div>
                                <div className="col-sm-3">
                                    <ul className="customList">
                                        <li>LED Screen</li>
                                        <li>Road Show</li>
                                        <li>Drone, Camera, CCTV</li>
                                        <li>DJ, Orchestra</li>
                                        <li>Brand Promotion</li>
                                    </ul>
                                </div>
                                <div className="col-sm-3">
                                    <ul className="customList">
                                        <li>Flower Decoration</li>
                                        <li>Live Performance</li>
                                        <li>Celebrity Management</li>
                                        <li>Professional Sound & Light</li>
                                        <li>Special Entry For Groom & Bride</li>
                                    </ul>
                                </div>
                                {/* Uncomment this section if needed
                                <div className="col-sm-6">
                                    <div className="otherImages">
                                        <img src="images/about-us/img1.jpg" alt="image 1" />
                                        <img src="images/about-us/img2.jpg" alt="image 2" />
                                        <img src="images/about-us/img3.jpg" alt="image 3" />
                                        <img src="images/about-us/img4.jpg" alt="image 4" />
                                        <img src="images/about-us/img5.jpg" alt="image 5" />
                                        <img src="images/about-us/img6.jpg" alt="image 6" />
                                        <img src="images/about-us/img7.jpg" alt="image 7" />
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AboutLS;