import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer id="footer" className="style2 widthBg">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-sm-5 col-md-3">
              <p className="footer-text">
                LS Event is a premier event management company based in Samastipur, Bihar, dedicated to curating unforgettable experiences. Our team combines youthful creativity with extensive industry experience to deliver innovative and personalized services.
              </p>
              <Link to="/about-us" className="btn style2">
                Read More
              </Link>
            </div>

            <div className="col-lg-3 col-sm-3 col-md-3 text-center">
              <div className="footer-link">
                <h5 className="font-h5">Useful Links</h5>
                <ul>
                  <li>
                    <img src="image/left-arrow.png" className="left-arrows" alt="" />
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <img src="image/left-arrow.png" className="left-arrows" alt="" />
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <img src="image/left-arrow.png" className="left-arrows" alt="" />
                    <Link to="/photo-gallery">Gallery</Link>
                  </li>
                  <li>
                    <img src="image/left-arrow.png" className="left-arrows" alt="" />
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-md-3 text-center">
              <div className="footer-link">
                <h5 className="font-h5">Services</h5>
                <ul>
                  <li>
                    <img src="image/left-arrow.png" className="left-arrows" alt="" />
                    <Link to="/theme-based-party">Theme Based Party</Link>
                  </li>
                  <li>
                    <img src="image/left-arrow.png" className="left-arrows" alt="" />
                    <Link to="/wedding-cernony">Wedding Ceremony</Link>
                  </li>
                  <li>
                    <img src="image/left-arrow.png" className="left-arrows" alt="" />
                    <Link to="/ring-cermony">Ring Ceremony</Link>
                  </li>
                  <li>
                    <img src="image/left-arrow.png" className="left-arrows" alt="" />
                    <Link to="/birthday-party">Birthday Party</Link>
                  </li>
                  <li>
                    <img src="image/left-arrow.png" className="left-arrows" alt="" />
                    <Link to="/private-event">Private Event</Link>
                  </li>
                  <li>
                    <img src="image/left-arrow.png" className="left-arrows" alt="" />
                    <Link to="/brand-pormotion">Brand Promotion</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-md-3">
              <div className="footer-contact">
                <h5 className="font-h5">Contact Us</h5>
                <div className="contact-slide">
                  <div className="icon icon-location-1"></div>
                  <p>Address Address Address Adress Adress PINCODE</p>
                </div>
                <div className="contact-slide">
                  <div className="icon icon-phone"></div>
                  <p>+91-1111111111</p>
                </div>
                <div className="contact-slide">
                  <div className="icon icon-message"></div>
                  <a href="mailto:navin.nk36@gmail.com">email@gmail.com</a>
                </div>
                <div className="sosal-midiya">
                  <ul>
                    <li>
                      <a href="#" target="_blank" rel="noopener noreferrer">
                        <span className="icon icon-facebook"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank" rel="noopener noreferrer">
                        <span className="icon icon-instagram"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank" rel="noopener noreferrer">
                        <span className="fa fa-youtube" style={{ color: '#fff', marginLeft: '7px' }}></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom" style={{ textAlign: 'center' }}>
            <p>
              Copyright &copy; <span></span> LS Event | All Rights Reserved Created By: <a href="https://www.dreambspl.com/" target="_blank" rel="noopener noreferrer">Dream Blend Solutions Pvt. Ltd.</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;