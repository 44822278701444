import React, { useState, useEffect } from "react";
import "./slider.css";

const Slider = ({ images, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState('fade-in');

  useEffect(() => {
    const slideInterval = setInterval(() => {
      handleSlideChange('next');
    }, interval);

    return () => clearInterval(slideInterval);
  }, [currentIndex]);

  const handleSlideChange = (direction) => {
    setFadeClass('fade-out'); // Start fade-out animation
    setTimeout(() => {
      if (direction === 'next') {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      } else if (direction === 'prev') {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
      }
      setFadeClass('fade-in'); // Start fade-in animation after updating slide
    }, 300); // Delay to sync with fade-out time
  };

  const goToSpecificSlide = (index) => {
    setFadeClass('fade-out');
    setTimeout(() => {
      setCurrentIndex(index);
      setFadeClass('fade-in');
    }, 300);
  };

  return (
    <div className="slider">
      <button className="prev" onClick={() => handleSlideChange('prev')}>
        &#10094;
      </button>

      <div className={`slide-container ${fadeClass}`}>
        {images.map((image, index) => (
          <div
            className={`slide ${index === currentIndex ? "active" : ""}`}
            key={index}
          >
            {index === currentIndex && (
              <img src={image} alt={`Slide ${index}`} />
            )}
          </div>
        ))}
      </div>

      <button className="next" onClick={() => handleSlideChange('next')}>
        &#10095;
      </button>

      <div className="dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => goToSpecificSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;
