import React from 'react';
import Gallery from '../components/Gallery';
// import './Gallery.css'; // You can add the styles in a separate CSS file
// import Lightbox from 'lightbox-react';
// import 'lightbox-react/style.css'; // Lightbox styles

const GallerySection = () => {
  // const [lightboxOpen, setLightboxOpen] = React.useState(false);
  // const [photoIndex, setPhotoIndex] = React.useState(0);
  // const images = [
  //   'gallery/1.jpg', 'gallery/2.jpg', 'gallery/3.jpg',
  //   'gallery/4.jpg', 'gallery/5.jpg', 'gallery/6.jpg',
  //   'gallery/7.jpg', 'gallery/8.jpg', 'gallery/9.jpg',
  //   'gallery/10.jpg'
  // ];
  // const handleOpenLightbox = (index) => {
  //   setPhotoIndex(index);
  //   setLightboxOpen(true);
  // };

  return (
    <div className="page">
      <Gallery/>
      <section className="gallery-section">
        <div className="container">
          <div className="sub-title2">
            <h2>Video Gallery</h2>
            <div className="icon"><em className="icon icon-heading-icon"></em></div>
          </div>
        </div><br />
        <div className="row">
          {[
            "https://www.youtube.com/embed/UPpg2O_wKpw?si=TqEs0clCKDZj-WNc",
            "https://www.youtube.com/embed/3RvmJx6IIUM?si=G9CskCzYpjyF4x8g",
            "https://www.youtube.com/embed/Q6spocXdIMA?si=bH5tUkVumGJXwvm6"
          ].map((videoUrl, index) => (
            <div className="col-md-4" key={index}>
              <iframe
                width="100%"
                height="400px"
                src={videoUrl}
                title={`YouTube video player ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default GallerySection;
