import React from 'react';
import QuickEnquiry from '../components/QuickEnquiry';

const EventManagement = () => {
  return (
    <div style={{ backgroundColor: 'rgb(225, 248, 255)' }}>
      <div className="page">
        <section className="content">
          <div className="container">
            <div className="blog-page">
              <div className="row" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px' }}>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="blog-slide">
                    <div className="blog-info">
                      <div className="sub-title3">
                        <h2>Theme Based Party</h2>
                      </div>
                      <p style={{ textAlign: 'justify' }}>
                        At DBSs, we specialize in transforming spaces with captivating theme-based decorations that cater to every occasion. Whether it's a corporate gathering, a wedding celebration, or a milestone birthday, our expertise lies in creating immersive environments that leave a lasting impression.
                      </p>
                      <p>
                        For corporate events, we seamlessly blend professionalism with creativity, offering sleek and sophisticated themes that reflect your brand identity. From futuristic tech-inspired setups to elegant gala themes, we ensure every detail aligns with your corporate image.
                      </p>
                      <p>
                        Weddings at DBSs are transformed into fairy tales with enchanting themes like romantic garden parties, vintage elegance, or modern chic. We personalize each element, from floral arrangements to lighting, to capture the essence of your love story.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="right-blog">
                    <div className="subscribe-blog">
                      {/* Uncomment to enable form
                      <h3>Quick Enquiry</h3>
                      <form action="#" method="post">
                        <div className="input-box">
                          <input type="text" placeholder="Event Type *" name="event_type" required />
                        </div>
                        <div className="input-box">
                          <input type="text" placeholder="Mobile No *" name="mobile" maxLength="10" pattern="^[6-9]\d{9}$" required />
                        </div>
                        <div className="input-box">
                          <input type="text" placeholder="Event Location *" name="event_location" required />
                        </div>
                        <div className="input-box">
                          <input type="text" placeholder="Select Date" id="datepicker2" name="event_date" required />
                        </div>
                        <div className="submit-box">
                          <input type="submit" value="Submit" className="btn" name="submit" />
                        </div>
                      </form>
                      */}
                      <img src="image/service/1.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="otherInfo">
          <div className="container">
            <div className="heading">
              <div className="icon"><em className="icon icon-heading-icon"></em></div>
              <div className="text">
                <h2>Theme Based Party Services</h2>
              </div>
              <div className="stickLine"></div>
            </div>
            <div className="service-first">
              <div className="serv2">
                <img src="image/service/pandal.jpg" className="serv-icons" alt="" />
                <h3>Fancy Pandal</h3>
                <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's fancy pandal in your city features exquisite design and luxurious amenities, offering an unforgettable experience for all attendees.</p>
              </div>
              <div className="serv2">
                <img src="image/service/flower.jpg" className="serv-icons" alt="" />
                <h3>Flower Decoration</h3>
                <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's flower decorations in your city are renowned for their artistic elegance and meticulous attention to detail, creating enchanting settings for any occasion.</p>
              </div>
              <div className="serv2">
                <img src="image/service/musicband.jpg" className="serv-icons" alt="" />
                <h3>Music Band</h3>
                <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's live music band in your city delivers captivating performances with soulful singing and energetic melodies, ensuring a memorable atmosphere for all guests.</p>
              </div>
              <div className="break"></div>
              <div className="serv2">
                <img src="image/service/catering.jpg" className="serv-icons" alt="" />
                <h3>Catering Service</h3>
                <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's catering service in your city offers a delightful array of culinary delights with impeccable presentation and exceptional taste, perfect for elevating any event to a memorable occasion.</p>
              </div>
              <div className="serv2">
                <img src="image/service/photography.jpg" className="serv-icons" alt="" />
                <h3>Photography & Videography</h3>
                <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's photography and videography services in your city capture the essence of every moment with artistic flair and professional expertise, ensuring lasting memories of your special occasions.</p>
              </div>
              <div className="serv2">
                <img src="image/service/drone.jpg" className="serv-icons" alt="" />
                <h3>Drone Camera & CCTV</h3>
                <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's drone camera and CCTV services in your city provide cutting-edge aerial and surveillance capabilities, enhancing event security and capturing stunning perspectives from above.</p>
              </div>
              <div className="break"></div>
            </div>
          </div>
        </section>
        <QuickEnquiry/>
      </div>
    </div>
  );
};

export default EventManagement;
