import React from 'react'

export default function Gallery() {
  return (
    
<section className="gallery-section">
<div className="container">
  <div className="sub-title2">
    <h2>Photo Gallery</h2>
    <div className="icon"><em className="icon icon-heading-icon"></em></div>
  </div>
</div>
<div className="gallery-row d-flex justify-content-center gap-3 flex-wrap">
  {/* Repeated Gallery Items */}
  {["wed 1.jpg", "8.jpg", "9.jpg", "1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg"].map((img, index) => (
    <div className="gallery-box rounded" key={index}>
      <img src={`admin/gallery/${img}`} alt="" height="auto" width="275" />
      <a href={`admin/gallery/${img}`} data-lightbox="example-set" className="icon icon-search">&nbsp;</a>
    </div>
  ))}
</div>
</section>
  )
}
