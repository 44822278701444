import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Layout/Header';
import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import Footer from './Layout/Footer';
import EventManagement from './pages/EventManagement';
import WeddingCeremony from './pages/WeddingCeremony';
import RingCeremony from './pages/RingCeremony';
import BirthdayPartyPage from './pages/BirthdayPartyPage';
import PrivateEvent from './pages/PrivateEvent';
import BrandPromotion from './pages/BrandPromotion';
import GallerySection from './pages/GallerySection';
import ContactUs from './pages/ContactUs';
// other imports...

function App() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/theme-based-party" element={<EventManagement/>} />
        <Route path="/wedding-ceremony" element={<WeddingCeremony/>} />
        <Route path="/ring-ceremony" element={<RingCeremony/>} />
        <Route path="/birthday-party" element={<BirthdayPartyPage/>} />
        <Route path="/private-event" element={<PrivateEvent/>} />
        <Route path="/brand-promotion" element={<BrandPromotion/>} />
        <Route path="/gallery" element={<GallerySection/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
        {/* Add other routes here */}
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
