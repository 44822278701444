import React, { useState } from "react";
import { Link } from 'react-router-dom';



const FirstHeader = () => {
  

  return (
    <header id="header">
      <div className="quck-link">
        <div className="container">
          <div className="mail" style={{ color: "#FFFFFF", fontWeight: "600" }}>
            <span style={{ color: "#000" }}>Follow Us : </span>&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook-square" style={{ color: "#000", fontSize: "18px" }}></i>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram" style={{ color: "#000", fontSize: "18px" }}></i>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-youtube" style={{ color: "#000", fontSize: "18px" }}></i>
            </a>
          </div>

          <div className="right-link">
            <span className="icon icon-address" style={{ color: "#000", fontWeight: "bold" }}></span>
            <span style={{ color: "#000", fontWeight: "bold" }}>email@gmail.com</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <i className="fa fa-whatsapp" style={{ color: "#000", fontWeight: "bold" }}></i>
            <span style={{ color: "#000", fontWeight: "bold" }}>
              <a href="https://api.whatsapp.com/send?phone=+91%11111111&text=Hi,%20I%20have%20shared%20my%20interest%20for%20exploring%20your%20services.%20Please%20Contact%20Me%20Soon.%20Thanks" target="_blank" rel="noopener noreferrer" style={{ color: "#000" }}>
                +91-11111111
              </a>
            </span>
          </div>
        </div>
      </div>

      <nav id="nav-main">
        <div className="container">
          <div className="navbar navbar-inverse">
            <div className="navbar-header">
              <Link to="/" className="navbar-brand">
              <h2 style={{color:'white'}}>Logo</h2>
              </Link>
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span className="icon1-barMenu"></span>
                <span className="icon1-barMenu"></span>
                <span className="icon1-barMenu"></span>
              </button>
            </div>
            <div className="navbar-collapse collapse">
              <ul className="nav navbar-nav">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="single-col">
                  <Link to="/about-us">About Us</Link>
                </li>
                <li className="single-col">
                <a href="/theme-based-party" onClick={(e) => e.preventDefault()}>We Organize</a>

                  <ul>
                    <li>
                      <Link to="/theme-based-party">Theme Based Party</Link>
                    </li>
                    <li>
                      <Link to="/wedding-ceremony">Wedding Ceremony</Link>
                    </li>
                    <li>
                      <Link to="/ring-ceremony">Ring Ceremony</Link>
                    </li>
                    <li>
                      <Link to="/birthday-party">Birthday Party</Link>
                    </li>
                    <li>
                      <Link to="/private-event">Private Event</Link>
                    </li>
                    <li>
                      <Link to="/brand-promotion">Brand Promotion</Link>
                    </li>
                  </ul>
                </li>
                <li className="single-col">
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

// Quick Enquiry Component (Commented out section from HTML)
const QuickEnquiry = () => {
  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send the form data as JSON
    const response = await fetch(
      "https://demo.educatorians.com/send_email.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          mobile,
          message,
        }),
      }
    );

    const result = await response.json();
    if (result.status === "success") {
      alert("Message sent successfully!");
    } else {
      alert(`Error: ${result.message}`);
    }
  };

  return (
    <>
      <div onClick={openPopup}>
        <img src="image/enquiry.png" alt="" className="phone-still cursor-pointer" />
      </div>

      {/* Lightbox for Quick Enquiry (hidden by default) */}
      <div id="lightz" className="lightbox2" style={{ display: 'block' }}>
        <div id="login-popup">
          <div style={{ overflow: 'hidden' }}>
            <div onClick={closePopup}>
              <img src="image/close-icon.png" className="close-2 cursor-pointer" alt="close" />
            </div>
          </div>
          <h2>Quick Enquiry</h2>
          <div className="seller-detail">
            <li>Please fill in the info to call now</li>
          </div>
          <form method="POST" onSubmit={handleSubmit}>
            <li>
              <input 
                value={name}
                onChange={(e) => setName(e.target.value)} type="text" name="name" id="name" placeholder="Name" className="pop-field" required />
            </li>
            <li>
              <input
                type="number"
                name="number"
                id="number"
                placeholder="Mobile No."
                className="pop-field"
                required
                onKeyPress={(e) => {
                  if (e.target.value.length === 10) e.preventDefault();
                }}
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </li>
            <li>
              <input type="email" name="email" id="email" placeholder="Email ID" className="pop-field" required />
            </li>
            <li>
              <textarea
                name="message"
                id="message"
                className="pop-fieldz"
                cols="20"
                rows="4"
                placeholder="Enter Message"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </li>
            <input type="submit" name="submit" className="popup-sign" value="Submit" />
          </form>
          <div className="break"></div>
        </div>
      </div>
    </>
  );
};

const openPopup = () => {
  const popup = document.getElementById('lightz');
  // const fade = document.getElementById('fade');
  
  if (popup) { 
    popup.style.display = 'block';
    // fade.style.display = 'block';
  } else {
    console.error('Popup or fade element not found');
  }
};

const closePopup = () => {
  const popup = document.getElementById('lightz');
  // const fade = document.getElementById('fade');
  
  if (popup) {
    popup.style.display = 'none';
    // fade.style.display = 'none';
  } else {
    console.error('Popup or fade element not found');
  }
};

// Exporting main App component
const Header = () => {
  return (
    <div>
      <FirstHeader />
      <QuickEnquiry />
    </div>
  );
};

export default Header;
