import React from 'react';
import QuickEnquiry from '../components/QuickEnquiry';
// import './YourComponent.css'; // Include your CSS file if needed

const WeddingCeremony = () => {
    return (
        <div style={{ backgroundColor: 'rgb(225, 248, 255)' }}>
            <div className="page">
                <section className="content">
                    <div className="container">
                        <div className="blog-page">
                            <div className="row" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px' }}>
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <div className="blog-slide">
                                        <div className="blog-info">
                                            <div className="sub-title3">
                                                <h2>Wedding Ceremony</h2>
                                            </div>
                                            <p style={{ textAlign: 'justify', lineHeight: '29px' }}>
                                                DBS, we understand the deep significance your wedding day holds in your life's journey. It marks a pivotal moment, where love, commitment, and dreams converge into a singular celebration of unity. With this profound understanding, we specialize in providing expert wedding planning services designed to meticulously craft a flawless event that mirrors the unique essence of your love story. Our dedicated team of seasoned wedding planners brings a wealth of experience and passion to every detail, ensuring that your vision is brought to life with precision and care. From the initial consultation to the final farewell, we work closely with you to understand your desires, preferences, and dreams, tailoring each aspect of the event to reflect your personality and style.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <div className="right-blog">
                                        <div className="subscribe-blog">
                                            <img src="image/service/7.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="otherInfo">
                    <div className="container">
                        <div className="heading">
                            <div className="icon"><em className="icon icon-heading-icon"></em></div>
                            <div className="text">
                                <h2>Wedding Services</h2>
                            </div>
                            <div className="stickLine"></div>
                            <div className="service-first">
                                <div className="serv2">
                                    <img src="image/service/pandal.jpg" className="serv-icons" alt="" />
                                    <h3>Fancy Pandal</h3>
                                    <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's fancy pandal in your city features exquisite design and luxurious amenities, offering an unforgettable experience for all attendees.</p>
                                </div>
                                <div className="serv2">
                                    <img src="image/service/flower.jpg" className="serv-icons" alt="" />
                                    <h3>Flower Decoration</h3>
                                    <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's flower decorations in your city are renowned for their artistic elegance and meticulous attention to detail, creating enchanting settings for any occasion.</p>
                                </div>
                                <div className="serv2">
                                    <img src="image/service/musicband.jpg" className="serv-icons" alt="" />
                                    <h3>Music Band</h3>
                                    <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's live music band in your city delivers captivating performances with soulful singing and energetic melodies, ensuring a memorable atmosphere for all guests.</p>
                                </div>
                                <div className="break"></div>
                                <div className="serv2">
                                    <img src="image/service/catering.jpg" className="serv-icons" alt="" />
                                    <h3>Catering Service</h3>
                                    <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's catering service in your city offers a delightful array of culinary delights with impeccable presentation and exceptional taste, perfect for elevating any event to a memorable occasion.</p>
                                </div>
                                <div className="serv2">
                                    <img src="image/service/photography.jpg" className="serv-icons" alt="" />
                                    <h3>Photography & Videography</h3>
                                    <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's photography and videography services in your city capture the essence of every moment with artistic flair and professional expertise, ensuring lasting memories of your special occasions.</p>
                                </div>
                                <div className="serv2">
                                    <img src="image/service/drone.jpg" className="serv-icons" alt="" />
                                    <h3>Drone Camera & CCTV</h3>
                                    <p style={{ marginLeft: '10px', marginRight: '10px' }}>DBS's drone camera and CCTV services in your city provide cutting-edge aerial and surveillance capabilities, enhancing event security and capturing stunning perspectives from above.</p>
                                </div>
                                <div className="break"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <QuickEnquiry/>
            </div>
        </div>
    );
};

export default WeddingCeremony;
